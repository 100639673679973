<template>
  <div class="Tags pt-2 pt-lg-4">
    <Navbar />

    <div class="container">
      <div class="row py-3 mt-5 flex-md-row-reverse">
        <div class="col-md-7 col-lg-8 mb-4">
          <div class="row justify-content-center">
            <div class="col-12 col-lg-10 mb-3">
              <h2>{{ detailActivity.title }}</h2>
              <div class="d-flex justify-content-between">
                <div><i class="bi-person"></i> <small>Admin</small></div>
                <div>
                  <i class="bi-chat-left"></i> <small>0 Komentar</small>
                </div>
              </div>
              <div class="mb-3">
                <i class="bi-calendar"></i>
                <small> {{ detailActivity.published }}</small>
              </div>
              <img
                :src="detailActivity.thumbnail"
                class="img-fluid rounded-3 mb-3"
              />
              <p v-html="detailActivity.text"></p>
              <!-- <h4><i class="bi-tags"></i> Tags :</h4>
              <ButtonTags /> -->
              <h4 class="mt-4">0 Komentar</h4>
              <hr class="my-3" />
              <!-- <div class="row">
                <div class="col-auto pe-0">
                  <img
                    src="@/assets/img/logo.png"
                    class="rounded-circle"
                    width="60"
                    height="60"
                  />
                </div>
                <div class="col">
                  <h5 class="mb-0">Rido Ananda</h5>
                  <small class="text-muted"
                    ><i class="bi-calendar"></i> 18 Juni 2020</small
                  >
                  <p class="mt-1">
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                    Hic cum voluptates similique et omnis fugit modi mollitia
                    facilis quidem laudantium?
                  </p>
                </div>
              </div> -->
            </div>
          </div>
        </div>

        <div class="col-md-5 col-lg-4 mb-4">
          <h3 class="header"><i class="bi-card-list"></i> Kegiatan Lainnya</h3>
          <div class="row mt-4">
            <div
              class="col-12 mb-3"
              v-for="activity in activities"
              :key="activity.id"
            >
              <CardKegiatan
                :title="activity.title"
                :thumbnail="activity.thumbnail"
                :published="activity.published"
                :slug="activity.slug"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer :tentang="layout.tentang_sekolah" />
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "DetailKegiatan",
  components: {
    Navbar: () => import("@/components/Navbar.vue"),
    CardKegiatan: () => import("@/components/CardKegiatan.vue"),
    // ButtonTags: () => import("@/components/ButtonTags.vue"),
    Footer: () => import("@/components/Footer.vue"),
  },
  data() {
    return {
      detailActivity: [],
      activities: [],
      layout: [],
    };
  },

  mounted() {
    this.getActivityDetail();
    this.getActivity();
    this.getLayout();
  },

  watch: {
    "$route.params.slug": function() {
      this.getActivityDetail();
      this.getActivity();
    },
  },

  methods: {
    async getActivityDetail() {
      let response = await axios.get(
        `activity/detail/${this.$route.params.slug}`
      );
      this.detailActivity = response.data.data;
    },
    async getActivity() {
      let response = await axios.get("activity/3");
      this.activities = response.data.data;
    },
    async getLayout() {
      let response = await axios.get("layout");
      this.layout = response.data.data;
    },
  },

  metaInfo() {
    return {
      title: `${this.detailActivity.title} - Kegiatan`,
      meta: [
        {
          vmid: `${this.detailActivity.text}`,
          name: `Lihat Kegiatan lainnya..`,
          content: `${this.detailActivity.text}`,
        },
      ],
    };
  },
};
</script>
